import React from 'react';
import CatalogCards from './catalogcards'


const DehumidifiersCatalog = () => {
    return(
        <>
            <CatalogCards />
        </>
        
    )
}

export default DehumidifiersCatalog