import React from 'react';
import DehumidifierHtml from './dehumidifierhtml'


const Dehumidifier = () => {
    return(
        <DehumidifierHtml />
    )
}

export default Dehumidifier