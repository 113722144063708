import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import TopDark from '../../Modules/Top/TopDarkRelative'
import Footer from '../../Modules/FooterPage'
import DehumidifiersCatalog from './Catalog';


const DehumidifierPage = () => {

    return(
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <TopDark />
                    </Col>
                </Row>
            </Container>
                    <div className="refeng-page-container">
                        <div className="postcontent">
                            <h1>Адсорбционный осушитель воздуха роторного типа.</h1>
                            <h2>Промышленные осушители воздуха, различные типы осушителей.</h2>
                            <h3>Когда следует выбирать адсорбционный осушитель</h3>
                            <p>Существует 3 способа осушки воздуха в помещении: ассимиляция (воздухообмен), конденсация и адсорбция.</p>
                            <p>Первый способ имеет малое распространение из-за высокого влагосодержания уличного воздуха в летний период, 
                                кроме того он требует затрат тепла или электричества на подогрев приточного воздуха в зимний период. 
                            </p>
                            <p>
                                Наиболее традиционный способ – конденсационный. Для большинства требуемых параметров температуры и относительной влажности 
                                этот способ имеет оптимальное сочетание капитальных и эксплуатационных затрат. Устройство конденсационных осушителей очень похоже 
                                на моноблочный кондиционер, на рынке присутствует огромное количество производителей и модельных линеек под разные задачи. 
                                Но не во всех ситуациях возможно применить конденсационную осушку.    
                            </p>
                            <p>
                                Условной границей выбора адсорбционной осушки можно считать переход в область отрицательных значений температуры точки росы 
                                (что соответствует параметрам +18°С /30%, +8°С/55%, +4°С/70% и т.д.) и влагосодержаний воздуха ниже примерно 5-6 г/кг.
                            </p>
                            <p>
                                Адсорбционные роторные осушители применяются в ситуациях, когда более традиционные конденсационные осушители применить невозможно или менее эффективно. 
                            </p>
                            
                            {/* Ammonia toxicity image */}
                            <Container fluid>
                                <Row>
                                    <Col xl={3}></Col>
                                    <Col xl={6}>
                                        <figure>
                                            <img style={{width: '100%'}} src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/equipment/dehumidifier/grafik_adsorb.jpg" alt="Виды фреонов" />
                                            <figcaption><em>Диаграмма условно показывает эффективность влагосъема конденсационных и адсорбционных осушителей в зависимости от температуры обрабатываемого воздуха.</em></figcaption>
                                        </figure>
                                    </Col>
                                    <Col xl={3}></Col>
                                </Row>
                            </Container>
                            <br/>

                            <p>
                                В отличие от конденсационных, адсорбционные осушители практически не имеют ограничений по температуре и влажности.
                            </p>
                            <h4>
                                Применяются в отраслях и ситуациях, когда требуется осушить воздух, имеющий низкую температуру, 
                                или требуется очень низкая относительная влажность: 
                            </h4>
                            <ul class="post-ul-dot">
                                <li class="post-li-dot">фармацевтическая промышленность</li>
                                <li class="post-li-dot">ледовые катки</li>
                                <li class="post-li-dot">холодильные камеры с требованиями к относительной влажности</li>
                                <li class="post-li-dot">различные технологические цеха (например, сублимационной сушки) с требованиями к относительной влажности</li>
                                <li class="post-li-dot">кондитерские производства</li>
                                <li class="post-li-dot">упаковочные производства</li>
                                <li class="post-li-dot">лаборатории</li>
                                <li class="post-li-dot">зернохранилища</li>
                                <li class="post-li-dot">и т.д.</li>
                            </ul>

                            <h4>Система осушения холодного воздуха с помощью роторного адсорбционного осушителя.</h4>
                            <p>
                                Адсорбционный роторный осушитель состоит из следующих основных компонентов: ротора, абсорбента, нагревателя и радиальных вентиляторов.
                            </p>
                            <p>
                                Осушающий ротор (большой вращающийся барабан в основе осушителя) имеет две разные зоны — «рабочую зону», 
                                которая составляет около 75 % площади ротора, и «зону регенерации», которая составляет оставшиеся 25 %. ротора. 
                                “Рабочий” воздух, поступающий в осушитель, протягивается через рабочую зону. Здесь влага поглощается влагопоглотителем. 
                                Далее “Рабочий” воздух возвращается в помещение. 
                            </p>
                            <p>
                                Для регенерации адсорбента через область регенерации ротора проходит так называемый воздух регенерации. 
                                Он проходит через секцию нагрева (до высоких температур) и далее попадает в ротор, высвобождая влагу из осушителя. 
                                Таким образом, влага переносится из ротора в воздух регенерации. Теплый влажный воздух, выходящий из зоны регенерации, 
                                как правило через воздуховоды выбрасывается вентиляторами наружу. Важный нюанс заключается в том, что остаточное тепло в 
                                роторе из зоны регенерации приводит к нагреву осушаемого “рабочего” потока воздуха, в среднем на 1.5К на каждый г/кг 
                                производительности осушки. Знание этого фактора необходимо для оценки дополнительных теплопритоков, для помещений с 
                                температурно-влажностным режимом и должно учитываться при подборе холодильного оборудования.
                            </p>
                            <p>
                                Нагреватель может быть следующих типов:
                            </p>
                            <ul class="post-ul-dot">
                                <li class="post-li-dot">электрический</li>
                                <li class="post-li-dot">на горячей воде</li>
                                <li class="post-li-dot">на пару</li>
                            </ul>
                            <p>
                                Адсорбционные осушители больших размеров предпочтительно использовать на горячей воде или на пару. 
                                Также в адсорбционных осушителях можно использовать системы рекуперации тепла, для большей энергоэффективности.
                            </p>

                             {/* Ammonia toxicity image */}
                             <Container fluid>
                                <Row>
                                    <Col xl={3}></Col>
                                    <Col xl={6}>
                                        <figure>
                                            <img style={{width: '100%'}} src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/equipment/dehumidifier/scheme_adsorb.jpg" alt="Схема работы адсорбционного роторного осушителя" />
                                            <figcaption><em>Схема работы адсорбционного роторного осушителя</em></figcaption>
                                        </figure>
                                    </Col>
                                    <Col xl={3}></Col>
                                </Row>
                            </Container>
                            <br/>

                            <p>
                                Для подбора осушителя необходимо знать требуемый температурно-влажностный режим, произвести инженерный расчет влагопритоков, 
                                учесть нюансы расположения и подобрать вентиляторы в осушителях с учетом достаточного напора и длины воздуховодов.
                            </p>
                            <hr></hr>
                            <br/>
                            <h3>
                                Осушители, которые мы предлагаем:
                            </h3>
                            <br/>
                                <DehumidifiersCatalog />
                            </div>
                        </div>
                            <Container fluid>
                            <Row>
                            <Col></Col>
                            <Col xl={6}>
                                <div class="videoWrapper">
                                    <iframe width="660" height="415" src="https://www.youtube.com/embed/8a7Oa42TVk0" title="Видеообзор роторного адсорбционного осушителя воздуха" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <p></p>
                                    <figcaption><em>Видеообзор роторного адсорбционного осушителя воздуха на складе медикаментов компании BIOCAD</em></figcaption>
                                </div>
                            </Col>
                            <Col></Col>
                        </Row>
                                </Container>                        
                        <Footer />
        </>
            )
    }

    export default DehumidifierPage;
    