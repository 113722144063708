import React from 'react';


const DehumidifiersCatalog = () => {
    return(
        <>
            <div class="ref-project-catalogcard-container">
    <div class="ref-project-catalogcard-1">
        <article class="catalogcard">
            <header class="catalogcard__thumb">
                <img src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/equipment/dehumidifier/catalog/60m3_h_dh.jpg" alt="Осушитель 60 кубических метров в час" />
            </header>

            <div class="catalogcard__body">
                <div class="catalogcard__title">Осушитель 60м<sup>3</sup>/час</div>
                {/* <div class="catalogcard__subtitle">Производство сыра в Калуге.</div> */}
            </div>

            {/* <footer class="catalogcard__footer">
                <span><a href="/equipment/dehumidifiers-catalog/sixty"><button class="catalogcard__button">Характеристики</button></a></span>
            </footer> */}
        </article>
    </div>
    <div class="ref-project-catalogcard-2">
        <article class="catalogcard">
            <header class="catalogcard__thumb">
                <img src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/equipment/dehumidifier/catalog/220m3_h_dh.jpg" alt="Осушитель 220 кубических метров в час" />
            </header>

            <div class="catalogcard__body">
                <div class="catalogcard__title">Осушитель 220м<sup>3</sup>/час</div>
                {/* <div class="catalogcard__subtitle">Производство и реализация сыра в Москве.</div> */}
            </div>

            {/* <footer class="catalogcard__footer">
                <span><a href="/realizovannye_proekty/proectinvest"><button class="catalogcard__button">Характеристики</button></a></span>
            </footer> */}
        </article>
    </div>
    <div class="ref-project-catalogcard-3">
        <article class="catalogcard">
            <header class="catalogcard__thumb">
                <img src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/equipment/dehumidifier/catalog/420m3_h_dh_3.jpg" alt="Осушитель 420 кубических метров в час" />

            </header>

            <div class="catalogcard__body">
                <div class="catalogcard__title">Осушитель 420м<sup>3</sup>/час</div>
                {/* <div class="catalogcard__subtitle">Новое сырное производство в реконструируемом здании</div> */}
            </div>

            {/* <footer class="catalogcard__footer">
                <span><a href="/realizovannye_proekty/milkprom"><button class="catalogcard__button">Характеристики</button></a></span>
            </footer> */}
        </article>
    </div>
    <div class="ref-project-catalogcard-4">
        <article class="catalogcard">
            <header class="catalogcard__thumb">
                <img src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/equipment/dehumidifier/catalog/580m3_h_dh_1.jpg" alt="Осушитель 580 кубических метров в час" />

            </header>

            <div class="catalogcard__body">
                <div class="catalogcard__title">Осушитель 580м<sup>3</sup>/час</div>
                {/* <div class="catalogcard__subtitle">Производство традиционных французских и альпийских сыров в Москве</div> */}
            </div>

            {/* <footer class="catalogcard__footer">
                <span><a href="/realizovannye_proekty/fromageamour"><button class="catalogcard__button">Характеристики</button></a></span>
            </footer> */}
        </article>
    </div>

<div class="ref-project-catalogcard-5">
        <article class="catalogcard">
            <header class="catalogcard__thumb">
                <img src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/equipment/dehumidifier/catalog/850m3_h_dh.jpg" alt="Осушитель 850 кубических метров в час" />

            </header>

            <div class="catalogcard__body">
                <div class="catalogcard__title">Осушитель 850м<sup>3</sup>/час</div>
                {/* <div class="catalogcard__subtitle">Новый цех по переработке 1’000 тонн молока в сутки и выпуску готовых сыров</div> */}
            </div>

            {/* <footer class="catalogcard__footer">
                <span><a href="/realizovannye_proekty/ecomilk-syr"><button class="catalogcard__button">Характеристики</button></a></span>
            </footer> */}
        </article>
    </div>

<div class="ref-project-catalogcard-6">
        <article class="catalogcard">
            <header class="catalogcard__thumb">
                <img src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/equipment/dehumidifier/catalog/1550m3_h_dh_1.jpg" alt="Осушитель 1550 кубических метров в час" />

            </header>

            <div class="catalogcard__body">
                <div class="catalogcard__title">Осушитель 1550м<sup>3</sup>/час</div>
                {/* <div class="catalogcard__subtitle">Новое производство сырной продукции ООО «Метромаркет», г.Пенза.</div> */}
            </div>

            {/* <footer class="catalogcard__footer">
                <span><a href="/realizovannye_proekty/penza-cheese"><button class="catalogcard__button">Характеристики</button></a></span>
            </footer> */}
        </article>
    </div>
<div class="ref-project-catalogcard-7">
        <article class="catalogcard">
            <header class="catalogcard__thumb">
                <img src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/equipment/dehumidifier/catalog/2000m3_h_dh_2.jpg" alt="Осушитель 2000 кубических метров в час" />

            </header>

            <div class="catalogcard__body">
                <div class="catalogcard__title">Осушитель 2000м<sup>3</sup>/час</div>
                {/* <div class="catalogcard__subtitle">Натуральные фермерские продукты., с.Гжель. Камера созревания полутвердых и твёрдых сыров. Общая площадь камер созревания сыров 50м².</div> */}
            </div>

            {/* <footer class="catalogcard__footer">
                <span><a href="/realizovannye_proekty/gjelskoe-podvorie"><button class="catalogcard__button">Характеристики</button></a></span>
            </footer> */}
        </article>
    </div>
    <div class="ref-project-catalogcard-8">
        <article class="catalogcard">
            <header class="catalogcard__thumb">
                <img src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/equipment/dehumidifier/catalog/3000m3_h_dh_1.jpg" alt="Осушитель 3000 кубических метров в час" />

            </header>

            <div class="catalogcard__body">
                <div class="catalogcard__title">Осушитель 3000м<sup>3</sup>/час</div>
                {/* <div class="catalogcard__subtitle">Натуральные фермерские продукты., с.Гжель. Камера созревания полутвердых и твёрдых сыров. Общая площадь камер созревания сыров 50м².</div> */}
            </div>

            {/* <footer class="catalogcard__footer">
                <span><a href="/realizovannye_proekty/gjelskoe-podvorie"><button class="catalogcard__button">Характеристики</button></a></span>
            </footer> */}
        </article>
    </div>
    <div class="ref-project-catalogcard-9">
        <article class="catalogcard">
            <header class="catalogcard__thumb">
                <img src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/equipment/dehumidifier/catalog/3500m3_h_dh_1.jpg" alt="Осушитель 3500 кубических метров в час" />

            </header>

            <div class="catalogcard__body">
                <div class="catalogcard__title">Осушитель 3500м<sup>3</sup>/час</div>
                {/* <div class="catalogcard__subtitle">Натуральные фермерские продукты., с.Гжель. Камера созревания полутвердых и твёрдых сыров. Общая площадь камер созревания сыров 50м².</div> */}
            </div>

            {/* <footer class="catalogcard__footer">
                <span><a href="/realizovannye_proekty/gjelskoe-podvorie"><button class="catalogcard__button">Характеристики</button></a></span>
            </footer> */}
        </article>
    </div>
    <div class="ref-project-catalogcard-10">
        <article class="catalogcard">
            <header class="catalogcard__thumb">
                <img src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/equipment/dehumidifier/catalog/5000m3_h_dh_2.jpg" alt="Осушитель 5000 кубических метров в час" />

            </header>

            <div class="catalogcard__body">
                <div class="catalogcard__title">Осушитель 5000м<sup>3</sup>/час</div>
                {/* <div class="catalogcard__subtitle">Натуральные фермерские продукты., с.Гжель. Камера созревания полутвердых и твёрдых сыров. Общая площадь камер созревания сыров 50м².</div> */}
            </div>

            {/* <footer class="catalogcard__footer">
                <span><a href="/realizovannye_proekty/gjelskoe-podvorie"><button class="catalogcard__button">Характеристики</button></a></span>
            </footer> */}
        </article>
    </div>
    <div class="ref-project-catalogcard-11">
        <article class="catalogcard">
            <header class="catalogcard__thumb">
                <img src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/equipment/dehumidifier/catalog/8000m3_h_dh_1.jpg" alt="Осушитель 8000 кубических метров в час" />

            </header>

            <div class="catalogcard__body">
                <div class="catalogcard__title">Осушитель 8000м<sup>3</sup>/час</div>
                {/* <div class="catalogcard__subtitle">Натуральные фермерские продукты., с.Гжель. Камера созревания полутвердых и твёрдых сыров. Общая площадь камер созревания сыров 50м².</div> */}
            </div>

            {/* <footer class="catalogcard__footer">
                <span><a href="/realizovannye_proekty/gjelskoe-podvorie"><button class="catalogcard__button">Характеристики</button></a></span>
            </footer> */}
        </article>
    </div>
    <div class="ref-project-catalogcard-12">
        <article class="catalogcard">
            <header class="catalogcard__thumb">
                <img src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/equipment/dehumidifier/catalog/9000m3_h_dh_1.jpg" alt="Осушитель 9000 кубических метров в час" />

            </header>

            <div class="catalogcard__body">
                <div class="catalogcard__title">Осушитель 9000м<sup>3</sup>/час</div>
                {/* <div class="catalogcard__subtitle">Натуральные фермерские продукты., с.Гжель. Камера созревания полутвердых и твёрдых сыров. Общая площадь камер созревания сыров 50м².</div> */}
            </div>

            {/* <footer class="catalogcard__footer">
                <span><a href="/realizovannye_proekty/gjelskoe-podvorie"><button class="catalogcard__button">Характеристики</button></a></span>
            </footer> */}
        </article>
    </div>
</div>
        </>
        
    )
}

export default DehumidifiersCatalog