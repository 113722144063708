import React from "react"
import Seo from "../../components/seo"

import DehumidComponent from "../../components/EquipmentPages/Dehumidifier"


const Dehumid = () => (

  <>
    <Seo 
      title="Приобрести адсорбционный осушитель воздуха роторного типа в Москве"
      description="Купить адсорбционный осушитель воздуха роторного типа в Москве вы можете у компании Рефинжиниринг."
      keywords="адсорбционный осушитель, осушитель воздуха роторного типа, адсорбционный осушитель воздуха, осушитель воздуха"
    />
    <DehumidComponent />
  </>
  
)

export default Dehumid